import { ArrowPathIcon, PaperAirplaneIcon } from "@heroicons/react/24/solid";
import cx from "classnames";
import { Suspense, useEffect, useLayoutEffect, useRef } from "react";
import {
	Await,
	Form,
	useAsyncValue,
	useLoaderData,
	useNavigation,
	useRevalidator,
	useRouteLoaderData,
} from "react-router-dom";
import bgUrl from "../assets/order-messages-bg.png";

type Message = {
	id: string;
	text: string;
	createdAt: { toDate(): Date };
	author: string;
};

type Props = { msg: Message; isSelf?: boolean };

const Message: React.FC<Props> = ({ msg, isSelf }) => {
	const date = msg.createdAt.toDate();
	return (
		<div
			className={cx(
				"flex rounded-md p-2 text-sm",
				isSelf
					? "items-start self-start rounded-tl-none bg-gray-300/90"
					: "items-end self-end rounded-tr-none bg-lime-300/90",
			)}
		>
			<p>{msg.text}</p>
			<time
				title={date.toLocaleString()}
				className="mx-1 self-end text-[10px] text-gray-500"
			>
				{date.toLocaleTimeString("en-GB", {
					hour: "2-digit",
					minute: "2-digit",
				})}
			</time>
		</div>
	);
};

const List: React.FC = () => {
	const userType = (useRouteLoaderData("app") as { userType?: string })
		.userType;
	const messages = useAsyncValue() as Message[];
	const formRef = useRef<HTMLFormElement>(null);
	const listRef = useRef<HTMLDivElement>(null);
	const { state } = useNavigation();
	const { revalidate } = useRevalidator();
	useLayoutEffect(() => {
		if (listRef.current) {
			listRef.current.scroll(0, listRef.current.scrollHeight);
		}
	}, [messages.length]);
	useEffect(() => {
		if (state === "loading") formRef.current?.reset();
	}, [state]);

	const groups = messages.reduce(
		(p, c) => {
			const date = c.createdAt.toDate().toJSON().split("T")[0]!;
			p[date] = p[date] || [];
			p[date]?.push(c);
			return p;
		},
		{} as Record<string, Message[]>,
	);

	return (
		<div
			className="my-2 flex flex-col rounded bg-stone-100 bg-[length:80px_80px]"
			style={{
				backgroundImage: `url(${bgUrl})`,
			}}
		>
			<div
				ref={listRef}
				className={cx(
					"flex flex-col gap-4",
					"max-h-[300px] min-h-[200px]",
					"overflow-auto",
				)}
			>
				{!messages.length && (
					<p
						className={cx(
							"my-auto max-w-prose self-center",
							"text-center text-sm text-neutral-400",
						)}
					>
						<b>No messages so far…</b>
						<br />
						You can exchange internal messages with{" "}
						{userType === "admin" ? "factory" : "sales"} by sending
						a message below. These messages are never seen by the
						customer.
					</p>
				)}
				{Object.entries(groups).map(([k, v]) => (
					<div className="p-2" key={k}>
						<h4
							className={cx(
								"sticky top-2 table",
								"mx-auto mb-4 px-2 py-1 text-sm ",
								"rounded bg-gray-200 ",
							)}
						>
							{new Date(k).toLocaleDateString("en-GB", {
								day: "numeric",
								month: "long",
								year: "numeric",
							})}
						</h4>
						<div className={"flex flex-col gap-4"}>
							{v.map((m) => (
								<Message
									key={m.id}
									msg={m}
									isSelf={m.author === userType}
								/>
							))}
						</div>
					</div>
				))}
			</div>
			<Form ref={formRef} className="relative m-2 mt-auto" method="post">
				<input type="hidden" name="type" value="message" />
				<input
					name="message"
					className="w-full rounded-lg p-2 text-sm outline-none"
					placeholder="Message"
					required
				/>
				<div className="absolute inset-y-0 right-2 flex gap-3">
					<button
						type="button"
						title="Check for new messages"
						disabled={state !== "idle"}
						onClick={revalidate}
					>
						<ArrowPathIcon className="h-4 w-4 text-neutral-300 hover:text-neutral-500" />
					</button>
					<button
						title="Send message"
						disabled={state !== "idle"}
						className="disabled:text-neutral-300"
					>
						<PaperAirplaneIcon className="h-4 w-4 text-neutral-500" />
					</button>
				</div>
			</Form>
		</div>
	);
};

const OrderMessages: React.FC = () => {
	const data = useLoaderData() as { items: Promise<any[]> };
	return (
		<Suspense>
			<Await resolve={data.items} children={<List />} />
		</Suspense>
	);
};

export default OrderMessages;
