import Vue from "vue";

import { functions } from "../lib/firebase";

import { getOrderLastEvent, toDate, fmtOrderRef } from "@launerlondon/shared";
import type {
	RetailOrder,
	Event as RetailOrderEvent,
	EventType,
} from "@launerlondon/shared";

export interface SendNotificationData {
	orderId: string;
	status: string;
	email: string;
}

interface Component extends Vue {
	userType: "admin" | "factory";
	order: RetailOrder;
	lastActionLabel: EventType;
	modalNotificationRecipient: string;
	showResendModal: boolean;
}

function fmtActivityTimestamp(event: RetailOrderEvent): string {
	const date = toDate(event.date);

	return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
}

function getActivityLabel(this: Component, activity: RetailOrderEvent): string {
	const { type } = activity;

	if (type === "placed") {
		if (this.lastActionLabel === "placed") {
			return "Order placed, payment to be collected via telephone";
		}

		return "Order placed";
	}

	if (type === "charged") {
		return "Payment processed successfuly";
	}

	if (type === "failed") {
		return `Failed: ${activity.message}`;
	}

	if (type === "approved") {
		return "Order approved and sent to factory for production";
	}

	if (type === "cancelled") {
		return "Order has been cancelled by the admin";
	}

	if (type === "started") {
		return "Order production has started";
	}

	if (type === "shipped") {
		return "Order completed and dispatched to the customer";
	}

	return type.toString();
}

async function resendNotification(
	this: Component,
	event: Event,
): Promise<void> {
	const form = event.target as HTMLFormElement;
	const button = form.querySelector(
		"button[type=submit]",
	) as HTMLButtonElement;
	const orderId = this.$route.params.orderId || "0000";
	const fn = functions().httpsCallable("sendNotificationCallable");
	button.disabled = true;
	button.classList.add("app-button_loading");
	try {
		const data: SendNotificationData = {
			orderId,
			status: "charged",
			email: this.modalNotificationRecipient,
		};
		await fn(data);
		this.showResendModal = false;
	} catch (error) {
		alert((error as Error).message);
	}
	button.classList.remove("app-button_loading");
	button.disabled = false;
}

export default Vue.component("retail-order-activities", {
	data(this) {
		return {
			orderId: this.$route.params.orderId,
			modalNotificationRecipient: this.order.customer.email,
			showResendModal: false,
		};
	},
	computed: {
		lastActionLabel(this: Component): string {
			return getOrderLastEvent(this.order).type;
		},
	},
	methods: {
		fmtActivityTimestamp,
		getActivityLabel,
		resendNotification,
		fmtOrderRef,
	},
	props: ["order", "userType"],
	template: `
	<div class=retail-order-activities>
		<table class=retail-order-activities__list>
			<tr class=retail-order-activities__item>
				<th class=retail-order-activities__item__key>Date</th>
				<th class=retail-order-activities__item__label>Description</th>
				<th></th>
			</tr>
			<tr class=retail-order-activities__item v-for='act in order.events'>
				<td class=retail-order-activities__item__key>{{fmtActivityTimestamp(act)}}</td>
				<td :colspan="act.status==='charged'? 1 : 2" class=retail-order-activities__item__label>
					{{getActivityLabel(act)}}
				</td>
				<td class='retail-order-activities__item__action' v-if="act.type==='charged' && userType==='admin'">
					<button class=app-button @click="showResendModal=true">Resend Notification</button>
				</td>
			</tr>
		</table>
		<app-modal v-if=showResendModal @close='showResendModal=false'>
			<form @submit.prevent=resendNotification>
			<h2 class=app-modal__title>Resend notification for order #{{fmtOrderRef(order.ref)}}</h2>
			<label class=app-field>
				<span class=app-field__label>Email address</span>
				<input class=app-field__input v-model=modalNotificationRecipient required>
			</label>
			<button class=app-button type=button @click='showResendModal=false'>Go back</button>
			<button class=app-button type=submit>Submit</button>
			</form>
		</app-modal>

	</div>
	`,
});
