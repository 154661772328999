import { ActionFunction, json } from "react-router-dom";
import firebase from "firebase";
import { getUserType } from "./utils";
import { VoucherEvent, generateVoucher } from "@launerlondon/shared";
import toast from "react-hot-toast";

export const OrderAction: ActionFunction = async (q) => {
	const { request, params } = q;
	const userType = await getUserType();

	if (!userType) throw new Response("missing userType", { status: 400 });

	const form = await request.formData();
	const type = form.get("type")?.toString();
	const ref = firebase
		.firestore()
		.collection(params.account ? "wholesale-orders" : "retail-orders")
		.doc(params.id!);
	if (type === "message" && request.method === "POST") {
		const message = form.get("message")?.toString();
		if (!message) throw new Response("missing message", { status: 400 });
		await ref.collection("messages").doc().set({
			text: message,
			author: userType,
			createdAt: firebase.firestore.FieldValue.serverTimestamp(),
		});
		return { ok: true };
	}
	throw new Response("not implemented", { status: 501 });
};

export const StockAction: ActionFunction = async ({ request }) => {
	const db = firebase.firestore().collection("stock");
	const data = await request.formData();
	const sku = data.get("sku")?.toString();
	const total = Number.parseInt(data.get("total")?.toString() || "0");
	if (!sku) {
		throw new Response("missing sku", { status: 403 });
	}
	const doc = db.doc(sku);

	if (request.method === "POST") {
		await doc.set({
			total,
			counter: 0,
			createdAt: firebase.firestore.FieldValue.serverTimestamp(),
		});
	}
	if (request.method === "PUT") {
		await doc.update({ total });
	}
	if (request.method === "DELETE") {
		await doc.delete();
	}
	return json({ ok: true });
};

export const VouchersAction: ActionFunction = async ({ request }) => {
	const db = firebase.firestore().collection("vouchers");
	const data = await request.formData();
	if (request.method === "POST") {
		const amount = parseInt(data.get("amount")?.toString() || "0");
		const currency = data.get("currency")?.toString() || "GBP";
		const doc = db.doc();
		const voucher = await generateVoucher(amount, currency);
		await toast.promise(
			doc.set(voucher),
			{
				loading: "Creating voucher, please wait...",
				error: (e) => e.message,
				success: "Voucher created",
			},
			{ id: "voucher" },
		);
		return json({ ok: true });
	}
	throw new Response(null, { status: 405, statusText: "Method Not Allowed" });
};

export const VoucherAction: ActionFunction = async ({ params, request }) => {
	const db = firebase.firestore().collection("vouchers");
	const data = await request.formData();
	if (request.method === "PATCH") {
		const disabled = data.get("disabled")?.toString() === "true";
		const ref = db.doc(params.id);
		const event: VoucherEvent = {
			type: disabled ? "disable" : "enable",
			date: new Date(),
			amount: 0,
		};
		await toast.promise(
			ref.update({
				disabled,
				events: firebase.firestore.FieldValue.arrayUnion(event),
			}),
			{
				loading: "Updating voucher, please wait...",
				error: (e) => e.message,
				success: "Voucher updated",
			},
			{ id: "voucher" },
		);
		return json({ ok: true });
	}
	throw new Response(null, { status: 405, statusText: "Method Not Allowed" });
};
